import * as Sentry from '@sentry/react';
import StoryChief from '@/storychief';
import getGraphQlBreadcrumb from './utils/getGraphQlBreadcrumb';

function groupEvents(event, hint) {
  const error = hint?.originalException?.toString();

  if (
    /IndexSizeError/i.test(error) &&
    event.breadcrumbs.some((b) => /DraftStyleDefault|DraftEditor/.test(b.message))
  ) {
    // eslint-disable-next-line no-param-reassign
    event.fingerprint = ['group-draftjs-index-size-error'];
  }

  return event;
}

function initSentry() {
  if (StoryChief && StoryChief.shouldCaptureErrorsInSentry) {
    const { user, account, appVersion, environment } = StoryChief;
    const ignoreErrors = {
      common: [
        // Source: https://docs.sentry.io/clients/javascript/tips/#decluttering-sentry
        'top.GLOBALS',
        'originalCreateNotification',
        'canvas.contentDocument',
        'MyApp_RemoveAllHighlights',
        'http://tt.epicplay.com',
        "Can't find variable: ZiteReader",
        'jigsaw is not defined',
        'ComboSearch is not defined',
        'http://loading.retry.widdit.com/',
        'atomicFindClose',
        'fb_xd_fragment',
        'bmi_SafeAddOnload',
        'EBCallBackMessageReceived',
        'conduitPage',
        'Invalid lock token',
        'The user aborted a request',
        'Internal server error',
      ],
      superagent: ['404 NotFound', 'Unsuccessful HTTP response', 'the network is offline'],
      resizeObserver: [
        'ResizeObserver loop completed with undelivered notifications',
        'ResizeObserver loop limit exceeded',
      ],
      notFound: ['No query results for model'],
      validation: ['Validation failed'],
      auth: ['Unauthenticated', 'This action is unauthorized'],
      fetch: ['Failed to fetch'],
      apollo: ['Response not successful: Received status code'],
    };

    let tracesSampleRate = 0.0;
    if (environment === 'production') {
      tracesSampleRate = 0.05;
    } else if (environment === 'test') {
      tracesSampleRate = 0.5;
    }

    if (user) {
      Sentry.setUser({ id: user.id, email: user.email, username: user.fullname });
    }

    if (account) {
      Sentry.setTag('account', account.name);
    }

    Sentry.setTag('logger', 'javascript');

    Sentry.init({
      dsn: 'https://49f0e205f1f2435b90606e21a2ba7254@o67360.ingest.sentry.io/6215212',
      release: appVersion,
      environment,
      integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
      tracesSampleRate,
      // Store sessions only for sessions when an error occurred.
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0.25,
      denyUrls: [
        // ignore errors from https://clarity.microsoft.com/
        /clarity\.ms/i,
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // 3rd party scripts loaded via Google Tag Manager
        /googletagmanager\.com/i,
        /google-analytics\.com/i,
        /snap\.licdn\.com/i,
        /cdn\.segment\.com/i,
        /stats\.pusher\.com/i,
        /gstatic\.com/i,
        /googleadservices\.com/i,
        /growsumo\.com/i,
        /serve\.albacross\.com/i,
        /firstpromoter\.com/i,
      ],
      ignoreErrors: Object.values(ignoreErrors).flat(),
      beforeSend(event, hint) {
        return groupEvents(event, hint);
      },
      beforeBreadcrumb(breadcrumb, hint) {
        return getGraphQlBreadcrumb(breadcrumb, hint);
      },
    });
  }
}

initSentry();
