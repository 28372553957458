(function main() {
  $(document).ready(() => {
    function showFrequencyOptions() {
      $('.mailchimp_rss_schedule').addClass('hidden');
      switch ($('#mailchimp_rss_frequency').val()) {
        case 'daily':
          $('#mailchimp_rss_schedule_daily').removeClass('hidden');
          break;
        case 'weekly':
          $('#mailchimp_rss_schedule_weekly').removeClass('hidden');
          break;
        case 'monthly':
          $('#mailchimp_rss_schedule_monthly').removeClass('hidden');
          break;
        default:
          break;
      }
    }
    showFrequencyOptions();
    $('#mailchimp_rss_frequency').on('change', showFrequencyOptions);
  });
})(jQuery);
