/* eslint func-names: off */
/* eslint no-plusplus: off */
/* eslint no-alert: off */
/* eslint no-extend-native: off */
/* eslint new-cap: off */
import Echo from 'laravel-echo';
import { Settings } from 'luxon';
import jQuery from 'jquery';
import StoryChief from './packages/storychief';
import 'pusher-js';

Settings.defaultLocale = 'en';
if (import.meta.env.DEV) {
  window.jQuery = jQuery;
  window.$ = jQuery;
}
// Required by 3rd party Node modules
window.global = window;

// Add forEach support
HTMLCollection.prototype.forEach = Array.prototype.forEach;
// Add method to remove all elements on built-in objects
function removeAll() {
  for (let i = this.length - 1; i >= 0; i--) {
    if (this[i]) {
      this[i].remove();
    }
  }
}

NodeList.prototype.remove = removeAll;
HTMLCollection.prototype.remove = removeAll;

window.StoryChief.startIntercomMessage = StoryChief.startIntercomMessage;

(function main() {
  function onWindowResize() {
    $('.js-affix').each(function () {
      $(this).width($(this).parent().width());
    });
  }

  function onWindowScroll() {
    // Get current scroll position
    const currentScrollPos = $(document).scrollTop();
    // Iterate through all node
    $('.js-scrollspy > ul > li > a').each(function () {
      const curLink = $(this);
      const refElem = $(curLink.attr('href'));
      const refElemTop = refElem.offset().top - 76;
      const refElemBottom = refElemTop + (refElem.outerHeight(true) - 75);
      // Compare the value of current position and the every section position in each scroll
      if (refElemTop < currentScrollPos && refElemBottom > currentScrollPos) {
        // Remove class active in all nav
        $(this).parent().parent().find('li').removeClass('active');
        // Add class active
        curLink.parent().addClass('active');
      }
    });
  }

  $(document).ready(() => {
    $('[data-toggle="tooltip"]').tooltip({ html: true });
    $('.js-affix').affix({
      offset: {
        top: (el) => el.parent().offset().top - $('main').offset().top - 15,
      },
    });
    $('.js-affix').each(function () {
      $(this).width($(this).parent().width());
    });

    $(window).resize(() => onWindowResize());

    // Smooth scrolling when click to nav
    $('.js-scrollspy > ul > li > a').click(function (e) {
      e.preventDefault();
      const curLink = $(this);
      const scrollPoint = $(curLink.attr('href')).offset().top - 75;
      $('body,html').animate(
        {
          scrollTop: scrollPoint,
        },
        300,
      );
    });
    $(window).scroll(() => onWindowScroll());
    onWindowScroll();

    // Alter anchor scroll position on page load
    const locationHash = window.location.hash;
    if (locationHash && locationHash.indexOf('/') === -1) {
      setTimeout(() => {
        try {
          const topOffset = $(window.location.hash).offset()?.top;
          if (topOffset) {
            $(document).scrollTop(topOffset - 75);
          }
        } catch {
          // Catch error if the hash inside the URL is not a valid selector.
        }
      }, 1);
    }
  });
})(jQuery);

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: StoryChief.pusher.key,
  cluster: 'eu',
  encrypted: true,
  authEndpoint: `${StoryChief.dashboardBasePath}/broadcasting/auth`,
  csrfToken: StoryChief.csrfToken,
});
