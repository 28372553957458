import StoryChief from '@/storychief';

function getGraphQlBreadcrumbData(data) {
  if (data.variables && Object.keys(data.variables).length > 0) {
    const variables = data.variables;

    return {
      variables: JSON.stringify(variables),
    };
  }

  return null;
}

/**
 * @param {object} breadcrumb An object with the same structure as Sentry's Breadcrumb object.
 * @param {object} hint An object with the same structure as Sentry's BreadcrumbHint object.
 * @returns {object}
 */
export default function getGraphQlBreadcrumb(breadcrumb, hint) {
  const isGraphQlRequest = breadcrumb?.data?.url === StoryChief.graphqlUrl;

  try {
    if (isGraphQlRequest) {
      const graphQlRequestInput = hint.input.find((i) => i?.body);
      const data = JSON.parse(graphQlRequestInput.body);

      if (data.operationName) {
        return {
          type: 'default',
          category: `graphql.${data.query.startsWith('mutation ') ? 'mutation' : 'query'}`,
          level: 'info',
          data: getGraphQlBreadcrumbData(data),
          message: data.operationName,
          timestamp: breadcrumb.timestamp,
        };
      }
    }
  } catch (error) {
    // Skip if there is an issue generating the GraphQL breadcrumb
    // eslint-disable-next-line no-console
    console.error(error);
  }

  // Return unmodified breadcrumb
  return breadcrumb;
}
